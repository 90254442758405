import React from 'react';

function GameEndTurn({ onClick }) {
  return (
    <div
      style={{
        position: 'absolute',
        top: '1vw',
        right: '2vw',
        width: '15vw',
        // border: '1px solid blue',
      }}
    >
      <button
        onClick={onClick}
        style={{
          // background: 'black',
          zIndex: 6,
          margin: 0,
          fontFamily: "'Black Ops One', cursive",
          fontSize: '2vw',
          // boxShadow: '0 0px 15px rgba(0, 0, 0, 1)',
          backgroundColor: '#487346',
          color: 'white',
          // borderRadius: '2vw',
          padding: '1.5 2vw',
          width: '15vw',
        }}
      >
        End Turn
      </button>
    </div>
  );
}
export default GameEndTurn;
