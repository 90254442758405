import React, { useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ExtractionAPI from '../services/ExtractionAPI';
import { GamesContext } from '../context/ExtractionContext';
import useSound from 'use-sound';
import createSound from '../assets/audio/450613__breviceps__8-bit-sound-1-up.wav';
import './Games.css';
import GameMenu from '../components/GameMenu';

function Games() {
  const history = useHistory();
  const { games, setGames } = useContext(GamesContext);
  console.log('Load <Games>', games);
  const [play] = useSound(createSound);

  useEffect(() => {
    // On lood
    async function onLoad() {
      // Fetch game list
      const { rows } = await ExtractionAPI.listGames();
      setGames(rows);
      console.log('Fetch Games', games);
    }
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function joinGame(gameId) {
    try {
      play();
      const player = await ExtractionAPI.joinGame(gameId);
      console.log('Joined game', player.gameId);
      history.push(`/lobby/${player.gameId}`);
    } catch (e) {
      console.error(e);
    }
  }
  async function createGame() {
    try {
      play();
      const game = await ExtractionAPI.createGame();
      console.log('created game', game);
      joinGame(game.id);
    } catch (e) {
      console.error(e);
    }
    // Handle Login here

    // redirect
    // history.push('/game');
  }

  return (
    <div className="Games">
      <GameMenu />
      <button className="join-game" onClick={createGame}>
        Create game
      </button>
      <article className="section">
        <header>
          <span className="left-title">Open Games </span>
          <span className="right-title">Games ({games.length})</span>
        </header>
        <div
          style={{
            maxHeight: '60vh',
            overflowY: 'auto',
            marginBottom: '1vw',
          }}
        >
          <ul>
            {games.map((game, index) => (
              <li onClick={() => joinGame(game.id)}>
                {game.name}{' '}
                <span style={{ float: 'right' }}>
                  ({game.players.length}/8)
                </span>
              </li>
            ))}
          </ul>
        </div>
      </article>
    </div>
  );
}

export default Games;
