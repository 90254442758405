import React from 'react';
import actionTypes from '../constants/actionTypes';

function GameConfirmAction({
  pathEnd,
  actionType,
  handleMove,
  handleLoot,
  handleAttack,
  handleHealLimbs,
  handleCallExtract,
  handleExtract,
  allowMove,
  allowLoot,
  allowAttack,
  playerLoot,
}) {
  const selectedHealLimbs = actionType === actionTypes.HEAL_LIMBS;
  const selectedCallExtract = actionType === actionTypes.CALL_EXTRACT;
  const selectedExtract = actionType === actionTypes.EXTRACT;
  console.log('GameConfirmAction =actionType', actionType, selectedCallExtract);
  if (pathEnd || selectedHealLimbs || selectedCallExtract || selectedExtract)
    return (
      <div
        style={{
          width: '100vw',
          height: '10vw',
          backgroundColor: 'rgb(29, 30, 34)',
          position: 'fixed',
          bottom: 0,
          zIndex: 7,
          color: 'white',
          textAlign: 'center',
          lineHeight: '6vw',
          boxShadow: '0 0 1vw 2vw rgb(29, 30, 34)',
          fontFamily: "'Black Ops One', cursive",
          fontSize: '6vw',
        }}
      >
        {allowMove && pathEnd && (
          <button onClick={handleMove} className="confirm">
            Move
          </button>
        )}
        {allowLoot && pathEnd && (
          <button onClick={() => handleLoot(null)} className="confirm">
            Loot
          </button>
        )}
        {playerLoot.length && pathEnd
          ? playerLoot.map((item, index) => (
              <button
                key={item.id}
                onClick={() => handleLoot(item.id)}
                className="confirm"
              >
                Loot {item.name}
              </button>
            ))
          : null}

        {allowAttack && pathEnd && (
          <button onClick={handleAttack} className="confirm">
            Attack
          </button>
        )}
        {selectedHealLimbs && !pathEnd && (
          <button onClick={handleHealLimbs} className="confirm">
            Heal Limbs
          </button>
        )}
        {selectedCallExtract && !pathEnd && (
          <button onClick={handleCallExtract} className="confirm">
            Call Extract
          </button>
        )}
        {selectedExtract && !pathEnd && (
          <button onClick={handleExtract} className="confirm">
            Extract!
          </button>
        )}
      </div>
    );
  return null;
}
export default GameConfirmAction;
