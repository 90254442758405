import React from 'react';

function GamePlayCard({ name, onClick }) {
  console.log('name', name);
  return (
    <div
      style={{ right: '5vw', position: 'absolute', bottom: '0vh', zIndex: 7 }}
    >
      <div className="pulse-container">
        <span onClick={onClick} className="pulse-button">
          Play
        </span>
      </div>
      {/* <button
        onClick={onClick}
        className="timeleft"
        style={{
          background: 'rgb(29, 30, 34)',
          zIndex: 8,
          color: 'white',
          borderRadius: '2vw',
          padding: '2vw',
          fontFamily: "'Black Ops One', cursive",
          fontSize: '3vh',
        }}
      ></button> */}
    </div>
  );
}
export default GamePlayCard;
