import React, { useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import './GameLobby.css';
import ExtractionAPI from '../services/ExtractionAPI';
import { GameContext, UserContext } from '../context/ExtractionContext';
import SocketService from '../services/SocketService';
import useSound from 'use-sound';
import joinedSound from '../assets/audio/275072__kwahmah-02__doorbell-a.wav';
import GameMenu from '../components/GameMenu';
import playerIcon from '../assets/player.svg';

function GameLobby() {
  let { gameId } = useParams();
  const history = useHistory();
  const { game, setGame } = useContext(GameContext);
  const { user } = useContext(UserContext);
  const [playJoined] = useSound(joinedSound);

  useEffect(() => {
    async function onLoad() {
      // on load
      console.log('GameLobby game', game);

      const data = await ExtractionAPI.getGame(gameId);
      console.log('game onLoad GameLobby', data);

      setGame(data);
      SocketService.on('playerJoined', async ({ gameId }) => {
        playJoined();
        console.log('player joined event gameId', gameId);
        const data = await ExtractionAPI.getGame(gameId);
        console.log('data playerJoined', data);
        setGame(data);
      });

      SocketService.on('playerLeft', async ({ gameId }) => {
        console.log('player left event gameId', gameId);
        const data = await ExtractionAPI.getGame(gameId);
        console.log('data playerLeft', data);

        setGame(data);

        // getGame(gameId);
      });

      SocketService.on('gameStarted', async ({ gameId }) => {
        console.log('game started event', gameId);

        // const data = await ExtractionAPI.getGame(gameId);
        // console.log('data gameStarted', data);
        // setGame(game);
        history.replace(`/game/${gameId}`);
      });
      return () => {
        // remove socket listener
        console.log('GameLobby -> Remove socket listeners');
        SocketService.off('gameStarted');
        SocketService.off('playerJoined');
        SocketService.off('playerLeft');
      };
    }
    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId]);
  // function toggleReady(game) {
  //   // Toggle Ready
  // }
  async function leaveGame() {
    console.log('Leave game...');
    // Leave game
    await ExtractionAPI.leaveGame(gameId);

    // redirect back
    history.goBack();
  }
  async function startGame() {
    // Start game
    const response = await ExtractionAPI.startGame(gameId);
    console.log('response', response);

    // history.replace(`/game/${gameId}`);
  }
  if (!game) return <div className="loading">Loading...</div>;
  console.log('xgame', game);
  return (
    <div className="GameLobby">
      <GameMenu />
      <article className="section">
        <header>
          <span className="left-title">{game.name}'s game </span>
          <span className="right-title">Players ({game.players.length}/8)</span>
        </header>
        <div
          style={{
            maxHeight: '60vh',
            overflowY: 'auto',
            marginBottom: '1vw',
          }}
        >
          <ul>
            {game.players.map((player, i) => (
              <li key={i}>
                <img
                  alt="Extraction Menu"
                  src={playerIcon}
                  style={{ width: '2vw', verticalAlign: 'middle' }}
                />{' '}
                {player.name}
              </li>
            ))}
          </ul>
          {/* <button onClick={toggleReady}>Ready</button> */}
          {user.id === game.userId && (
            <button className="start-game" onClick={startGame}>
              Start
            </button>
          )}
          <button className="leave-game" onClick={leaveGame}>
            Leave
          </button>
        </div>
      </article>
    </div>
  );
}

export default GameLobby;
