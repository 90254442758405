import React from 'react';

function GameActionLog({ actionLog, setShowActionLog }) {
  console.log('actionLog', actionLog);
  return (
    <div
      onClick={() => setShowActionLog(false)}
      style={{
        position: 'absolute',
        bottom: '2vw',
        right: '2vw',
        width: '13vw',
        display: 'inline',
        color: 'rgb(201 183 135)',
        padding: '1vw',
        background: 'rgba(0, 0, 0, .2)',
        boxShadow: '0 0px 15px rgba(0, 0, 0, .5)',
        // border: '1px solid blue',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          padding: '0 .5vw .5vw',
          fontSize: '.95vw',
          color: 'rgb(201 183 135)',

          fontFamily: "'GoodTimesRg-Regular'",
        }}
      >
        Action Log
      </div>

      <div
        style={{
          paddingTop: '.4vw',
          fontSize: '.8vw',
          fontFamily: "'Black Ops One', cursive",
          fontWeight: '400',
          color: 'white',
        }}
      >
        {actionLog.map((item) => (
          <div>- {item}</div>
        ))}
      </div>
    </div>
  );
}
export default GameActionLog;
