import axios from 'axios';
// const baseUrl = 'http://143.198.49.168:4040/api'; // ubuntu
// const baseUrl = 'http://127.0.0.1:4040/api';
const baseUrl = 'https://api.extraction-game.com/api';
// const baseUrl = 'https://extraction-api.ngrok.io/api';
axios.defaults.baseURL = baseUrl;

// Detect logout
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error('error', error);
    if (error?.response?.status === 401) {
      //place your reentry code
      console.error('401 Detected, removing token and redirecting');
      localStorage.removeItem('token');
      window.location = '/';
    }
    return error;
  }
);

const token = localStorage.getItem('token');
if (token) {
  console.log('token found', token);

  setAuthHeader(token);
}

function login(body) {
  return axios.post('/auth/guest', body);
}
async function listGames() {
  const { data } = await axios.get('/games');
  return data;
}
async function listLocations() {
  const { data } = await axios.get('/locations');
  return data;
}
async function getMe() {
  const { data } = await axios.get(`/users/me`);
  return data;
}
async function getGame(gameId) {
  const { data } = await axios.get(`/games/${gameId}`);
  return data;
}
async function getGamePlayer(gameId) {
  const { data } = await axios.get(`/games/${gameId}/player`);
  return data;
}
async function joinGame(gameId) {
  const { data } = await axios.post(`/games/join/${gameId}`);
  return data;
}
async function endTurn(turnId, body) {
  const { data } = await axios.post(`/games/endTurn/${turnId}`, body);
  return data;
}
async function leaveGame(gameId) {
  const { data } = await axios.post(`/games/leave/${gameId}`);
  return data;
}
async function startGame(gameId) {
  const { data } = await axios.post(`/games/start/${gameId}`);
  return data;
}
async function createGame(body) {
  const { data } = await axios.post('/games', body);
  return data;
}
async function createAction(body) {
  const { data } = await axios.post('/actions', body);
  return data;
}
function setAuthHeader(token) {
  localStorage.setItem('token', token);
  const authHeader = `Bearer ${token}`;
  axios.defaults.headers.common['Authorization'] = authHeader;
}
function logout() {
  localStorage.removeItem('token');
  axios.defaults.headers.common['Authorization'] = null;
}

const ExtractionAPI = {
  login,
  logout,
  listGames,
  listLocations,
  joinGame,
  leaveGame,
  startGame,
  getGame,
  getMe,
  getGamePlayer,
  createGame,
  createAction,
  endTurn,
  setAuthHeader,
};

export default ExtractionAPI;
