import actionTypes from '../constants/actionTypes';
import cardTypes from '../constants/cardTypes';
// audio

function buildActionMessage({
  game,
  player,
  action,
  addActionLog,
  players,
  setMessage,
  setAttackData,
  setDangerData,
  playMoveSound,
  playLootSound,
  playWeaponSound,
  playHelicopterSound,
  playHealSound,
}) {
  const { actionTypeId, result, data } = action;

  let delay = 3500;
  let msg = '';
  console.log('players', players);
  console.log('result', result);
  switch (actionTypeId) {
    case actionTypes.MOVE:
      playMoveSound();
      msg = `${player.name} moved ${result.distance} ${
        result.distance > 1 ? 'spaces' : 'space'
      }!`;
      break;
    case actionTypes.LOOT:
      playLootSound();
      msg = `${player.name} looted!`;
      if (action.result?.type === 'DANGER') setDangerData(action.result);

      break;
    case actionTypes.PLAY_LOOT: {
      // delay = 4000;
      const { cardTypeId } = result.card;
      switch (cardTypeId) {
        case cardTypes.WEAPON:
        case cardTypes.PERK:
          playWeaponSound();
          msg = `${player.name} equipped ${result.card.name}!`;
          break;

        default:
          msg = `${player.name} played ${result.card.name}!`;
      }
      break;
    }

    case actionTypes.ATTACK: {
      // delay = 3000;
      const targetPlayer = players.find(
        (item) => item.id === data.targetPlayerId
      );
      console.log('targetPlayer', targetPlayer);
      // msg = `${player.name} attacked ${targetPlayer.name}!`;
      setAttackData({
        player,
        targetPlayer,
        result: action.result,
      });
      // if(action.result) action.result.array.forEach(attack => {
      // 	msg += `Hit Roll: ${attack.hitRoll}`
      // });
      // msg +=

      break;
    }

    case actionTypes.HEAL_LIMBS:
      // delay = 4000;
      playHealSound();
      msg = `${player.name} healed their limbs!`;
      break;

    case actionTypes.EXTRACT:
      msg = `${player.name} extracted!`;
      break;
    case actionTypes.CALL_EXTRACT:
      playHelicopterSound();
      msg = `${player.name} called in extract! Do not let them extract!`;
      // delay = 6000;
      break;

    default:
      break;
  }
  if (msg) {
    addActionLog(msg);
    if (game?.status === 'Completed') return;

    setMessage(msg);
    setTimeout(() => {
      setMessage('');
    }, delay);
  }
  //`${player.name} ${actionTypeLabels[actionTypeId]}!`
}

export default buildActionMessage;
