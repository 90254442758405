import React from 'react';
import historyIcon from '../assets/history.svg';

function GameShowActionLog({ setShowActionLog, showActionLog }) {
  return (
    <>
      <img
        alt="player"
        src={historyIcon}
        style={{
          position: 'absolute',
          right: '2.5vw',
          bottom: '3.5vw',
          width: '5.0vw',
          zIndex: 7,
          marginTop: '-47.3vw',
          // background: 'rgba(255,255,255,.5)',
          padding: '1vw',
          // boxShadow: '0 0px 15px rgba(0, 0, 0, .5)',

          borderRadius: '7vw',
        }}
        onClick={() => {
          console.log('showActionLog', showActionLog);
          setShowActionLog(!showActionLog);
        }}
      />
    </>
  );
}
export default GameShowActionLog;
