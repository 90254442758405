import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AddToHomescreen from 'react-add-to-homescreen';

// assets
import mp4 from '../assets/war.mp4';
import logo from '../assets/logo.svg';
import discord from '../assets/discord.png';

// audio
import intro from '../assets/audio/609666__baconandbeans__pistol-cock.mp3';

// services
import SocketService from '../services/SocketService';
import ExtractionAPI from '../services/ExtractionAPI';

// hooks
import useWindowDimensions from '../hooks/useWindowDimenions';
import useSound from 'use-sound';
// context
import { UserContext } from '../context/ExtractionContext';

function Login() {
  const { user, setUser } = useContext(UserContext);
  const [play] = useSound(intro);

  useEffect(() => {
    async function onLoad() {
      const token = localStorage.getItem('token');
      if (token) {
        // Get user
        console.log('Logged in User', user);
        history.push('/games');
      }
    }
    onLoad();
    // On load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();
  const [email, setEmail] = useState('');
  const { height, width } = useWindowDimensions();

  function enterFullscreen() {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    var cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  }
  function handleAddToHomescreenClick() {
    alert(`
1. Tap Share To button
2. Scroll down and tap "Add to Home Screen" button`);
  }
  async function login() {
    play();
    try {
      const { status, data } = await ExtractionAPI.login({
        name: email,
        email,
      });
      if (status === 200) {
        const { token, user } = data;
        // Set Auth Header
        ExtractionAPI.setAuthHeader(token);
        // store userId
        setUser(user);
        localStorage.setItem('userId', user.id);
        // Login to Socket
        SocketService.emit('login', { id: parseInt(user.id, 10) });
        enterFullscreen();
      }
    } catch (e) {
      console.error(e);
    }
    // Handle Login here

    // redirect
    history.push('/games');
  }
  function onChange(e) {
    setEmail(e.target.value);
  }
  console.log('width/height', width, height);
  // Depending on height/width, set height or width so video is full screen
  const videoStyle =
    width * 0.5625 > height ? { width: '100vw' } : { height: '100vh' };
  return (
    <>
      <div className="Login">
        <div style={{ width: '100vw', height: '100vh' }}>
          <video
            id="introVideo"
            src={mp4}
            preload="auto"
            autoPlay
            playsInline
            loop
            style={videoStyle}
            muted
          ></video>
        </div>
        <div className="overlay"></div>
        <div className="login">
          <div style={{ marginBottom: '2vw' }}>
            <img alt="Extraction Menu" src={logo} style={{ width: '30vw' }} />
          </div>
          <div style={{ marginBottom: '2vw' }}>
            <input
              type="text"
              placeholder="What's your name?"
              value={email}
              onChange={onChange}
              style={{
                padding: '2vw',
                borderRadius: '1vw',
                fontFamily: "'Black Ops One', cursive",
              }}
            />
          </div>
          {/* <GoogleLoginButton /> */}
          <button disabled={!email} onClick={login}>
            PLAY
          </button>
          <br />
          <a
            href="https://discord.gg/DguAyCS94F"
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="Join the discord community"
              src={discord}
              style={{ width: '15vw', marginTop: '2vw' }}
            />
          </a>
        </div>
      </div>{' '}
      <AddToHomescreen
        title={'Add to homescreen (View in full screen mode)'}
        onAddToHomescreenClick={handleAddToHomescreenClick}
      />
    </>
  );
}

export default Login;
