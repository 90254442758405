import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import logo from '../assets/logo.svg';
import discord from '../assets/discord.png';
import ExtractionAPI from '../services/ExtractionAPI';

// context
import { GameContext } from '../context/ExtractionContext';

function GameMenu() {
  const history = useHistory();
  const { setGame } = useContext(GameContext);

  function logout() {
    var proceed = window.confirm('Are you sure you want to logout?');
    if (proceed) {
      ExtractionAPI.logout();
      setGame(null);
      history.push('/');

      // history.replace('/'); //proceed
    } else {
      //don't proceed
    }
  }

  return (
    <>
      <img
        alt="Logo"
        src={logo}
        style={{
          position: 'absolute',
          left: '0',
          top: '0',
          width: '20vw',
          // background: 'rgb(29, 30, 34)',
          borderRadius: '5vw',
          padding: '1.2vw',
        }}
        onClick={logout}
      />
      <a href="https://discord.gg/DguAyCS94F" target="_blank" rel="noreferrer">
        <img
          alt="Join the discord community"
          src={discord}
          style={{
            width: '10vw',
            position: 'absolute',
            left: '22vw',
            top: '1.65vw',
          }}
        />
      </a>
    </>
  );
}
export default GameMenu;
