import React from 'react';

function GameActions({
  isMyTurn,
  handleActionTypeSelect,
  actionType,
  actionsRemaining,
  allowedActions,
}) {
  // console.log('GameActions', actionType, allowedActions);
  if (!isMyTurn || !actionsRemaining) return null;
  return (
    <div
      style={{
        background: 'rgba(0, 0, 0, .2)',
        boxShadow: '0 0px 15px rgba(0, 0, 0, .5)',
        padding: '1vw',
        position: 'absolute',
        top: '8vw',
        right: '2vw',
        width: '13vw',
        // border: '2px solid red',
      }}
    >
      <div
        style={{
          textAlign: 'center',
          padding: '0 .5vw .5vw',
          fontSize: '.95vw',
          color: 'rgb(201 183 135)',

          fontFamily: "'GoodTimesRg-Regular'",
        }}
      >
        Available Actions
      </div>
      {allowedActions.map((action, i) => (
        <button
          key={action.actionTypeId}
          onClick={() => {
            handleActionTypeSelect(action.actionTypeId);
          }}
          style={{
            // background: 'black',
            zIndex: 6,
            fontFamily: "'Black Ops One', cursive",
            fontSize: '1.5vw',
            color: actionType === action.actionTypeId ? 'white' : 'black',
            // backgroundColor: activeIndex === i ? 'white' : 'none',
            // borderRadius: '2vw',
            padding: '1vw 1vw',
            // boxShadow:
            //   actionType === action.actionTypeId
            //     ? '0 0px 15px rgba(0, 0, 0, 1)'
            //     : 'none',
            width: '13vw',
            marginBottom: '1vw',
            marginLeft: 0,
            opacity: actionType === action.actionTypeId ? 1 : 0.7,
          }}
        >
          {action.label}
        </button>
      ))}
    </div>
  );
}
export default GameActions;
