import socketIO from 'socket.io-client';
// import {SOCKET_URL} from '../Config';
const SocketService = socketIO('wss://api.extraction-game.com', {
  // const SocketService = socketIO('wss://extraction-api.ngrok.io', {
  // const SocketService = socketIO('ws://143.198.49.168:4040', {
  // ubuntu
  // const SocketService = socketIO('ws://127.0.0.1:4040', {
  // path: '/socket.io/',
  transports: ['websocket'],
  jsonp: false,
});

export default SocketService;
