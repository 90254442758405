import React, { useState } from 'react';
import playerMat from '../assets/player-mat.svg';

function GamePlayerMat({
  showPlayerMat,
  setShowPlayerMat,
  player,
  setShowZoomedCard,
  setZoomedCard,
}) {
  const [loaded, setLoaded] = useState(false);
  // console.log('setZoomedCard', setZoomedCard);
  // console.log('setShowZoomedCard', setShowZoomedCard);
  return (
    <>
      {showPlayerMat && (
        <div
          className="playerMat"
          style={{
            zIndex: 7,
            position: 'absolute',
            left: '2vw',
            top: '1vw',
            width: '96vw',
          }}
        >
          {loaded && (
            <>
              {/* Broken Arm */}
              {player.isArmBroken && (
                <div
                  style={{
                    height: '2vw',
                    width: '2vw',
                    background: 'red',
                    position: '	absolute',
                    top: '11.75vw',
                    left: '37.9vw',
                  }}
                ></div>
              )}
              {/* Player Name */}
              <div
                style={{
                  // display: 'none',
                  height: '4.39vw',
                  lineHeight: '4.69vw',
                  fontSize: '6.19vw',
                  width: '57vw',
                  // textTransform: 'uppercase',
                  color: 'rgb(217,216,214)',
                  background: 'rgb(32,35,34)',
                  position: 'absolute',
                  top: '1.85vw',
                  left: '15.8vw',
                  // fontFamily: "'Black Ops One', cursive",
                  fontFamily: "'Quantico', sans-serif",
                }}
              >
                {player.name}
              </div>
              {/* Broken Leg */}
              {player.isLegBroken && (
                <div
                  style={{
                    height: '2vw',
                    width: '2vw',
                    background: 'red',
                    position: 'absolute',
                    top: '22.85vw',
                    left: '37.1vw',
                  }}
                ></div>
              )}
              {/* Weapon */}
              <div
                style={{
                  width: '15.9vw',
                  position: 'absolute',
                  top: '8.0vw',
                  left: '43.3vw',
                }}
              >
                {player.weaponCard && (
                  <img
                    className="card"
                    src={
                      require(`../assets/cards/${player.weaponCard.img}`)
                        .default
                    }
                    alt={player.weaponCard.name}
                    style={{
                      width: '15.9vw',
                    }}
                    onClick={() => {
                      setZoomedCard(player.weaponCard);
                      setShowZoomedCard(true);
                    }}
                  />
                )}
              </div>
              {/* Perk */}
              <div
                style={{
                  width: '15.9vw',
                  position: 'absolute',
                  top: '8.0vw',
                  left: '60.3vw',
                }}
              >
                {player.perkCard && (
                  <img
                    className="card"
                    src={
                      require(`../assets/cards/${player.perkCard.img}`).default
                    }
                    alt={player.perkCard.name}
                    style={{
                      width: '15.9vw',
                    }}
                    onClick={() => {
                      setZoomedCard(player.perkCard);
                      setShowZoomedCard(true);
                    }}
                  />
                )}
              </div>
              {/* Dropped Loot */}
              <div
                style={{
                  width: '14.9vw',
                  position: 'absolute',
                  top: '8.0vw',
                  left: '77.9vw',
                }}
              >
                {player.isLootDropped && (
                  <img
                    className="card"
                    src={require(`../assets/card_back.svg`).default}
                    alt={'Card Back'}
                    style={{
                      width: '14.9vw',
                    }}
                  />
                )}
              </div>
              {/* Head Armor */}
              {[...Array(player.headArmor)].map((item, index) => {
                const left = 3.1 * (index + 1);
                return (
                  <div
                    key={`headArmor${index}`}
                    style={{
                      height: '2vw',
                      width: '2vw',
                      background: 'red',
                      position: 'absolute',
                      top: '13.3vw',
                      left: `${14.25 + left}vw`,
                    }}
                  ></div>
                );
              })}
              {/* Chest Armor */}
              {[...Array(player.chestArmor)].map((item, index) => {
                const left = 3.3 * (index + 1);
                return (
                  <div
                    key={`chestArmor${index}`}
                    style={{
                      height: '2vw',
                      width: '2vw',
                      background: 'red',
                      position: 'absolute',
                      top: '19.15vw',
                      left: `${7.25 + left}vw`,
                    }}
                  ></div>
                );
              })}
              {/* HP */}
              {[...Array(player.hp >= 0 ? player.hp : 0)].map((item, index) => {
                const left = 3.295 * (index + 1);
                return (
                  <div
                    key={`hp${index}`}
                    style={{
                      height: '2vw',
                      width: '2vw',
                      background: 'red',
                      position: 'absolute',
                      top: '25.35vw',
                      left: `${0.65 + left}vw`,
                    }}
                  ></div>
                );
              })}
            </>
          )}
          <img
            alt="playerMat"
            src={playerMat}
            onClick={() => setShowPlayerMat(!showPlayerMat)}
            style={{
              zIndex: 7,
              // position: 'absolute',
              width: '100%',
            }}
            onLoad={() => setLoaded(true)}
          />{' '}
        </div>
      )}
    </>
  );
}
export default GamePlayerMat;
