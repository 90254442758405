import React from 'react';

function GameZoomCard({ card, setShowZoomedCard }) {
  if (!card) return null;
  return (
    <>
      {/* Location */}
      <div
        style={{
          width: '30vw',
          position: 'absolute',
          top: '2.0vw',
          left: '35vw',
          zIndex: 8,
        }}
      >
        <img
          alt={card.name}
          src={require(`../assets/cards/${card.img}`).default}
          onClick={() => setShowZoomedCard(false)}
          style={{
            width: '100%',
          }}
        />
      </div>
    </>
  );
}
export default GameZoomCard;
