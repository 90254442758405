import React, { useState, useEffect, useRef } from 'react';
import Dice from 'react-dice-roll';
// import ReactDice from 'react-dice-complete';
// import 'react-dice-complete/dist/react-dice-complete.css';
// audio
import useSound from 'use-sound';
import diceSound from '../assets/audio/618288__john-dark-souls__dice-roll.mp3';
import laughSound from '../assets/audio/632508__realmadpuppy__sinister-laugh.mp3';

function GameDanger({
  dangerData,
  setDangerData,
  setShowTargetPlayerMat,
  game,
  setMessage,
}) {
  const [step, setStep] = useState(0);
  const [rolled, setRolled] = useState(false);
  const diceRef = useRef(null);
  const [playDiceSound] = useSound(diceSound);
  const [playLaughSound] = useSound(laughSound);
  function resetState() {
    console.log('resetState');
    setRolled(false);
    setStep(0);
    setDangerData(null);
    setShowTargetPlayerMat(false);
  }

  function locationLabel(type, value, rollAgain) {
    switch (type) {
      case 'DANGER_DAMAGE':
        return `Took ${value} damage.${rollAgain ? ' Roll again...' : ''}`;

      case 'DANGER_GAIN_ACTION':
        return `Gain ${value} action${value > 1 ? 's.' : '.'}${
          rollAgain ? ' Roll again...' : ''
        }`;
      case 'DANGER_DEATH':
        return `Player ${value.name} died!`;
      default:
        return 'Unknown danger';
    }
  }
  useEffect(() => {
    function init() {
      // Roll
      if (!dangerData) return;

      diceRef.current.rollDice();
      playLaughSound();
      playDiceSound();

      // if(hit)
    }
    init();
  }, [dangerData]);
  if (!dangerData) return null;
  console.log('dangerData', dangerData);
  console.log('dangerData.value', dangerData.value);
  console.log('step', step);
  console.log('dangerData.value[step]', dangerData.value[step]);
  const { type, value, rollAgain, roll } = dangerData.value[step];

  return (
    <div
      style={{
        width: '90vw',
        // height: '20vw',
        top: '50%',
        transform: 'translateY(-50%)',
        padding: '0 5vw',
        backgroundColor: 'black',
        position: 'fixed',
        color: 'white',
        textAlign: 'center',
        lineHeight: '6vh',
        opacity: '1',
        boxShadow: '0 0 10px 20px black',
        fontFamily: "'Black Ops One', cursive",
        fontSize: '2vw',
        zIndex: 10,
      }}
    >
      <div>
        <div>Looted Danger! Roll to see outcome!</div>
        <div>
          <div style={{ width: '25vw', display: 'inline-block' }}>
            <img
              className="card"
              src={require(`../assets/cards/${dangerData.card.img}`).default}
              alt={dangerData.card.name}
              style={{
                left: '2vw',
                width: '20vw',
              }}
            />
          </div>
          <div
            style={{
              width: '25vw',
              display: 'inline-block',
              marginTop: '5vw',
              verticalAlign: 'top',
            }}
          >
            <Dice
              ref={diceRef}
              size={100}
              rollingTime={3000}
              cheatValue={roll}
              disabled={rolled}
              onRoll={(value) => {
                setRolled(true);
                setTimeout(() => {
                  if (dangerData.value[step + 1]) {
                    // go to next step
                    setStep(step + 1);
                    setRolled(false);
                    diceRef.current.rollDice();
                    playDiceSound();
                  } else {
                    resetState();

                    // finished
                    if (game.status === 'Completed') {
                      setMessage(
                        `${
                          game.winningPlayer
                            ? game.winningPlayer.name
                            : 'No one won!'
                        } wins!`
                      );
                    }
                  }
                }, 5000);
              }}
            />
          </div>
        </div>
        {/* <ReactDice
          numDice={2}
          rollDone={this.rollDoneCallback}
          ref={dice => this.reactDice = dice}
        	/> */}
        {rolled && (
          <>
            <div>{locationLabel(type, value, rollAgain)}</div>
          </>
        )}{' '}
      </div>
    </div>
  );
}
export default GameDanger;
