import React from 'react';
const activeWidth = '38vw';
const inactiveWidth = '13vw';

function GameHand({
  loot,
  activeCard,
  setActiveCard,
  showDropLoot,
  lootIdsToDrop,
  setLootIdsToDrop,
}) {
  console.log('hand', loot);
  // console.log('active', activeCard);
  function addDropLoot(loot) {
    console.log('lootIdsToDrop', lootIdsToDrop);
    console.log('loot', loot);
    lootIdsToDrop.push(loot);
    console.log('lootIdsToDrop.push(loot)');
    setLootIdsToDrop([...lootIdsToDrop]);
  }
  const filteredLoot = lootIdsToDrop
    ? loot.filter((item) => lootIdsToDrop.indexOf(item.id) === -1)
    : loot;
  console.log('filteredLoot', filteredLoot);
  return (
    <div
      className="hand"
      style={{
        position: 'absolute',
        bottom: '0vh',
        zIndex: 6,
        left: '0',
        height: '0',
        right: '0',
        textAlign: 'center',
      }}
    >
      {filteredLoot.map((item, i) => (
        <img
          className="card"
          key={`card${item.id}`}
          src={require(`../assets/cards/${item.card.img}`).default}
          alt={item.card.name}
          style={{
            width: activeCard === i ? activeWidth : inactiveWidth,
            marginTop: '-47.3vw',
          }}
          onClick={() => {
            if (showDropLoot) {
              if (loot.length - lootIdsToDrop.length > 5) addDropLoot(item.id);
            } else {
              setActiveCard(activeCard !== i ? i : null);
            }
          }}
        />
      ))}
    </div>
  );
}
export default GameHand;
