import React from 'react';
import Game from '../pages/Game';
import { useHistory } from 'react-router-dom';

function FlashMessage({ message, game }) {
  const history = useHistory();

  function goToLobby() {
    history.push(`/games/`);
  }
  return (
    <div
      style={{
        width: '90vw',
        // height: '20vw',
        top: '50%',
        transform: 'translateY(-50%)',
        padding: '0 5vw',
        backgroundColor: 'black',
        position: 'fixed',
        color: 'white',
        textAlign: 'center',
        lineHeight: '12vh',
        opacity: '.8',
        boxShadow: '0 0 10px 20px black',
        fontFamily: "'Black Ops One', cursive",
        fontSize: '4vw',
        zIndex: 10,
      }}
    >
      {message}
      {game && game.status === 'Completed' ? (
        <div>
          <button onClick={goToLobby} className="confirm">
            Go to Lobby
          </button>
        </div>
      ) : null}
    </div>
  );
}
export default FlashMessage;
