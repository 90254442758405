import React from 'react';
import ExtractionAPI from '../services/ExtractionAPI';

function DropLoot({
  setShowDropLoot,
  setLootIdsToDrop,
  lootIdsToDrop,
  loot,
  turnId,
}) {
  console.log('1lootIdsToDrop', lootIdsToDrop);
  const lootRemaining = loot.length - lootIdsToDrop.length - 5;
  async function handleConfirm() {
    await ExtractionAPI.endTurn(turnId, {
      droppedLoot: lootIdsToDrop,
    });
    setShowDropLoot(false);
    setLootIdsToDrop([]);
  }
  return (
    <div
      style={{
        width: '90vw',
        // height: '20vw',
        top: '0',
        padding: '0 5vw',
        backgroundColor: 'black',
        position: 'fixed',
        color: 'white',
        textAlign: 'center',
        lineHeight: '12vh',
        opacity: '.8',
        boxShadow: '0 0 10px 20px black',
        fontFamily: "'Black Ops One', cursive",
        fontSize: '4vw',
        zIndex: 10,
      }}
    >
      <div>Select {lootRemaining} cards to drop</div>
      {lootIdsToDrop.map((lootId) => {
        const selectedLoot = loot.find((item) => item.id === lootId);
        return (
          <img
            key={selectedLoot.id}
            alt={selectedLoot.card.name}
            src={require(`../assets/cards/${selectedLoot.card.img}`).default}
            onClick={() => {
              // remove from setLootIdsToDrop
              const updatedLoot = lootIdsToDrop.filter(
                (item) => item !== selectedLoot.id
              );
              setLootIdsToDrop(updatedLoot);
            }}
            style={{
              width: '8vw',
            }}
          />
        );
      })}
      <div>
        {lootRemaining === 0 && (
          <button onClick={handleConfirm}>Confirm</button>
        )}
      </div>
    </div>
  );
}
export default DropLoot;
