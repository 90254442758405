import React, { useState, useContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { use100vh } from 'react-div-100vh';

// lib
import { GridGenerator, HexUtils } from '../lib/react-hexgrid';
import validateAction from '../lib/validateAction';
import buildActionMessage from '../lib/buildActionMessage';

// services
import ExtractionAPI from '../services/ExtractionAPI';
import SocketService from '../services/SocketService';

// context
import { GameContext, UserContext } from '../context/ExtractionContext';

// components
import GameGrid from '../components/GameGrid';
import GameActionRemaining from '../components/GameActionsRemaining';
import GamePlayerMat from '../components/GamePlayerMat';
import GameActionLog from '../components/GameActionLog';
import GameHand from '../components/GameHand';
import GameMenu from '../components/GameMenu';
import FlashMessage from '../components/FlashMessage';
import GameTurn from '../components/GameTurn';
import GameEndTurn from '../components/GameEndTurn';
import GameConfirmAction from '../components/GameConfirmAction';
import GamePlayCard from '../components/GamePlayCard';
import GameAttack from '../components/GameAttack';
import GameShowPlayerMat from '../components/GameShowPlayerMat';
import GameShowActionLog from '../components/GameShowActionLog';
import GameDanger from '../components/GameDanger';
import GameZoomCard from '../components/GameZoomCard';
import GameActions from '../components/GameActions';

// constants
import settings from '../constants/settings';
import actionTypes from '../constants/actionTypes';
import cardTypes from '../constants/cardTypes';
import DropLoot from '../components/DropLoot';

// audio
import useSound from 'use-sound';
// Fallback if cant figure out audio issues;
// new Audio('/path/to/file.mp3').play()

// import startSound from '../assets/audio/189327__alxy__missile-lock-on-sound.mp3';
import moveSound from '../assets/audio/483924__zeinel__walking-on-grass.mp3';
import lootSound from '../assets/audio/505384__lartti__searching-cds-from-box.wav';
import weaponSound from '../assets/audio/609666__baconandbeans__pistol-cock.mp3';
import yourTurnSound from '../assets/audio/450613__breviceps__8-bit-sound-1-up.wav';
import helicopterSound from '../assets/audio/488089__mike-leister__helicopter.wav';
import healSound from '../assets/audio/592844__nuncaconoci__tearing-tape-from-roll.wav';
import GameObjective from '../components/GameObjective';

// Setup grid
const grid = GridGenerator.rectangle(10, 10);

function Game() {
  let { gameId } = useParams();
  const adjusted100vh = use100vh();

  // Context
  const { game, setGame } = useContext(GameContext);
  const { user } = useContext(UserContext);

  // state
  const [locations, setLocations] = useState(null);
  const [player, setPlayer] = useState(null);
  const [actionType, setActionType] = useState(1);
  // todo remove if no longer necessary
  const refActionType = useRef(actionType);
  const refLocations = useRef(locations);

  const [allowedActions, setAllowActions] = useState([]);
  const [playerLoot, setPlayerLoot] = useState([]);
  const [actionsRemaining, setActionsRemaining] = useState(0);
  const [extractPoints, setExtractPoints] = useState(0);
  const [hexagons, setHexagons] = useState(null);
  const [activeCard, setActiveCard] = useState(null);
  const [showPlayerMat, setShowPlayerMat] = useState(false);
  const [showActionLog, setShowActionLog] = useState(false);
  const [showTargetPlayerMat, setShowTargetPlayerMat] = useState(false);
  const [zoomedCard, setZoomedCard] = useState(false);
  const [showZoomedCard, setShowZoomedCard] = useState(false);
  const [allowMove, setAllowMove] = useState(false);
  const [allowLoot, setAllowLoot] = useState(false);
  const [allowPlayerLoot, setAllowPlayerLoot] = useState(false);
  const [allowAttack, setAllowAttack] = useState(false);
  const [message, setMessage] = useState('');
  const [targetPlayer, setTargetPlayer] = useState(null);
  const [attackData, setAttackData] = useState(null);
  const [dangerData, setDangerData] = useState(null);
  const [path, setPath] = useState({ start: null, end: null });
  const [showDropLoot, setShowDropLoot] = useState(false);
  const [lootIdsToDrop, setLootIdsToDrop] = useState([]);
  const [actionLog, setActionLog] = useState([]);

  // const [playStartSound] = useSound(startSound);
  const [playMoveSound] = useSound(moveSound);
  const [playLootSound] = useSound(lootSound);
  const [playWeaponSound] = useSound(weaponSound);
  const [playYourTurnWeaponSound] = useSound(yourTurnSound);
  const [playHelicopterSound] = useSound(helicopterSound);
  const [playHealSound] = useSound(healSound);

  function addActionLog(msg) {
    setActionLog((actionLog) => [msg, ...actionLog.slice(0, 4)]);
  }

  function handleSetActiveCard(value) {
    setActiveCard(value);
    setPath({
      ...path,
      end: null,
    });
  }
  async function handleConnect() {
    console.log('Connected to game..');
    window.location.reload();
    setMessage('');
  }
  async function handleDisconnect() {
    console.log('Detected disconnect..');
    // setIsConnected(false);
    setMessage('Disconnected. Reconnecting...');
  }
  // handlePlayerActionEvent
  async function handleEndTurnEvent({ game }) {
    console.log('handleEndTurnEvent');
    console.log('game', game);
    console.log('players', game.players);
    const player = game.currentTurn.player;
    console.log('player', player);
    // Set
    // reset path
    setPath({ start: null, end: null });
    const gamePlayerData = await ExtractionAPI.getGamePlayer(gameId);
    setPlayer(gamePlayerData);
    if (validateAction.isMyTurn(game, gamePlayerData))
      playYourTurnWeaponSound();
    setGame({ ...game, currentTurn: { ...game.currentTurn } });

    loadLoot(game, gamePlayerData, refLocations.current);
    const currentPlayer = document.getElementById(
      `player-${game.currentTurn.playerId}`
    );
    // scroll to active player
    currentPlayer.scrollIntoView({
      behavior: 'auto',
      block: 'center',
      inline: 'center',
    });
    setMessage(
      `${gamePlayerData.id === player.id ? 'Your' : `${player.name}'s`} turn`
    );
    setTimeout(() => {
      setMessage('');
    }, 3500);
  }
  // handlePlayerActionEvent
  async function handlePlayerActionEvent({ game, action }) {
    console.log('handlePlayerActionEvent');
    console.log(`action`, action);
    console.log('game action', game);
    console.log('players', game.players);
    // console.log('refLocations.current', refLocations.current);
    const player = game.players.find((item) => item.id === action.playerId);
    console.log('player', player);
    // playMoveSound();
    buildActionMessage({
      game,
      player,
      action,
      players: game.players,
      setMessage,
      addActionLog,
      setAttackData,
      setDangerData,
      playMoveSound,
      playLootSound,
      playWeaponSound,
      playHelicopterSound,
      playHealSound,
    });

    // reset path
    setPath({ start: null, end: null });
    const gamePlayerData = await ExtractionAPI.getGamePlayer(gameId);
    setPlayer(gamePlayerData);

    setGame({ ...game, currentTurn: { ...game.currentTurn } });
    console.log('gamePlayerData onMessage', gamePlayerData);

    loadLoot(game, gamePlayerData, refLocations.current);
    const currentPlayer = document.getElementById(
      `player-${game.currentTurn.playerId}`
    );
    // scroll to active player
    if (currentPlayer) {
      currentPlayer.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
    }
    // if Game is completed show message
    if (game?.status === 'Completed') {
      setMessage(
        `${game.winningPlayer ? game.winningPlayer.name : 'No one won!'} wins!`
      );
    }
  }
  // Handle key clicks
  const handleEsc = (event) => {
    if (event.keyCode === 27) {
      setShowPlayerMat(false);
      setShowTargetPlayerMat(false);
      setPath({
        ...path,
        end: null,
      });
      setZoomedCard(false);
      setShowZoomedCard(false);

      console.log('Escape');
    }
  };

  // Load loot
  function loadLoot(game, player, locations) {
    const allowedActions = [];

    console.log('loadLoot');
    console.log('game', game);
    console.log('player', player);
    console.log('locations', locations);
    console.log('actionType', actionType);
    console.log('refActionType.current', refActionType.current);
    const actionCount = game.currentTurn.actions.reduce(
      (p, action) => p + action.value,
      0
    );
    const extractionPoints = player.loots.reduce(
      (p, loot) => (loot.card.cardTypeId === 3 ? p + loot.card.data.value : p),
      0
    );
    setExtractPoints(extractionPoints);
    const actionsRemaining = settings.ACTIONS_PER_TURN - actionCount;
    setActionsRemaining(actionsRemaining);
    const maxMoveRange = player.isLegBroken
      ? Math.floor(actionsRemaining / 2)
      : actionsRemaining;
    // If maxRange is greater than 0, show move as allowedAction

    const playerHex = {
      q: player.locationX,
      r: player.locationY,
      s: player.locationZ,
    };

    // If your turn
    if (player.id === game.currentTurn.playerId) {
      setPath({ start: playerHex });
      // end turn automatically if player has no actions or is dead
      if (actionsRemaining === 0 || !player.isAlive)
        setTimeout(() => handleEndTurn({ game, player }), 2500);
    }
    // Check if loot is in range for allowedActions;
    let lootInRange = false;

    const gridWithLoot = grid.map((hex) => {
      // Highlight tiles that are next to the target (1 distance away)
      const matches = game.loots.filter((loot) => {
        return (
          (loot.playerId === null || loot.isDropped === true) &&
          loot.locationX === hex.q &&
          loot.locationY === hex.r
        );
      });

      hex.props = { data: { loot: [] } };
      hex.props.data.loot = matches;
      // set players move range if its your turn
      const distance = HexUtils.distance(playerHex, hex);
      if (matches.length && distance <= 1) lootInRange = true;

      if (player.id === game.currentTurn.playerId && actionsRemaining) {
        // Check actionType selected
        switch (refActionType.current) {
          case actionTypes.MOVE: {
            if (distance <= maxMoveRange) hex.props.className = 'moverange';

            break;
          }
          case actionTypes.LOOT: {
            const maxRange = 1;
            if (distance <= maxRange) hex.props.className = 'moverange';

            break;
          }
          case actionTypes.ATTACK: {
            if (player.weaponCard) {
              const weaponCard = player.weaponCard;
              // Check if in range
              const rangeBonus =
                player?.perkCard?.data?.type === 'PERK_MARKSMAN' ? 1 : 0;
              console.log('rangeBonus', rangeBonus);
              const minRange = weaponCard.data.minRange;
              const maxRange = weaponCard.data.maxRange + rangeBonus;
              if (distance <= maxRange && distance >= minRange)
                hex.props.className = 'moverange';
            }

            break;
          }
          default:
            break;
        }
      }
      // check for locations
      for (let i = 0; i < refLocations.current.length; i++) {
        const location = refLocations.current[i];
        if (
          hex.q === location.locationX &&
          hex.r === location.locationY &&
          hex.s === location.locationZ
        ) {
          hex.props.className = `location${i}`;
          hex.props.fill = `location${i}`;
          // console.log('hex player id..', player.id);
          hex.props.location = location;
          // console.log('hex', hex);
        }
      }
      // check for players
      for (let i = 0; i < game.players.length; i++) {
        const playerItem = game.players[i];
        if (!playerItem.isAlive) continue;
        if (
          hex.q === playerItem.locationX &&
          hex.r === playerItem.locationY &&
          hex.s === playerItem.locationZ
        ) {
          // Check if player is not you and its their turn
          hex.props.fill = 'player';
          if (playerItem.id === player.id) {
            // console.log('you');
            hex.props.className += ` active-player`;
          } else if (
            playerItem.id !== player.id &&
            game.currentTurn.playerId === playerItem.id
          ) {
            // console.log('other players turn');

            hex.props.className += ` loading-player`;
          }
          // else {
          //   hex.props.className += ` player${i}`;
          // }
          // If player is dead load a different fill
          // if (!playerItem.isAlive) hex.props.fill = 'dead';
          // console.log('player found');
          //hex.props.className = `player${i}`;
          // console.log('hex player id..', player.id);
          hex.props.player = playerItem;
          // console.log('hex', hex);
        }
      }
      return hex;
    });
    // Check for allowedActions based on gridWithLoot
    // Move
    if (maxMoveRange > 0)
      allowedActions.push({
        actionTypeId: actionTypes.MOVE,
        label: 'Move',
      });

    // Loot
    if (lootInRange)
      allowedActions.push({ actionTypeId: actionTypes.LOOT, label: 'Loot' });

    // Attack
    if (player.weaponCard)
      allowedActions.push({
        actionTypeId: actionTypes.ATTACK,
        label: 'Attack',
      });

    // Heal Limbs
    const healLimbsReduction =
      player?.perkCard?.data?.type === 'PERK_MED_SCHOOL'
        ? player?.perkCard?.data?.cost
        : 0;
    console.log('healLimbsReduction', healLimbsReduction);
    if (
      (player.isLegBroken || player.isArmBroken) &&
      actionsRemaining >= 4 - healLimbsReduction
    )
      allowedActions.push({
        actionTypeId: actionTypes.HEAL_LIMBS,
        label: 'Heal Limbs',
      });

    // Call Extract
    console.log('extractionPoints', extractionPoints);
    if (
      extractionPoints >= 6 &&
      actionsRemaining >= 4 &&
      !player.extractionLocationId
    )
      allowedActions.push({
        actionTypeId: actionTypes.CALL_EXTRACT,
        label: 'Call Extract',
      });

    // Extract
    if (
      player.locationX === player.extractionLocation?.locationX &&
      player.locationY === player.extractionLocation?.locationY &&
      player.locationZ === player.extractionLocation?.locationZ &&
      actionsRemaining >= 4
    )
      allowedActions.push({
        actionTypeId: actionTypes.EXTRACT,
        label: 'Extract!',
      });
    // Check if selected action is still available, if not set to first allowed.
    if (
      allowedActions.length &&
      allowedActions.findIndex(
        (item) => item.actionTypeId === refActionType.current
      ) === -1
    ) {
      setActionType(allowedActions[0].actionTypeId);

      console.log('change actionType to', allowedActions[0]);
    }
    setAllowActions(allowedActions);

    // console.log('gridWithLoot', gridWithLoot);
    setHexagons(gridWithLoot);
  }
  useEffect(() => {
    console.log('actionType changed!');
    refLocations.current = locations;
    refActionType.current = actionType;

    console.log('action refLocations.current', refLocations.current);
    if (game?.currentTurn && player && locations)
      loadLoot(game, player, refLocations.current);
  }, [actionType]);
  useEffect(() => {
    console.log('useEffect');
    async function onLoad() {
      // playStartSound();

      console.log(`<Game onLoad()...`);
      // console.log('user', user);
      if (!user) return;
      refLocations.current = locations;

      if (!refLocations.current) {
        console.log('Locations NULL');
        const locationData = await ExtractionAPI.listLocations();
        setLocations(locationData);
        return;
      }
      console.log('Locations LOADED');

      refActionType.current = actionType;

      window.addEventListener('keydown', handleEsc);

      console.log('Loaded...'); //
      console.log(`GameID:${gameId}`);
      const gameDataPromise = ExtractionAPI.getGame(gameId);
      const gamePlayerDataPromise = ExtractionAPI.getGamePlayer(gameId);
      const [gameData, gamePlayerData] = await Promise.all([
        gameDataPromise,
        gamePlayerDataPromise,
      ]);
      // console.log('gameData', gameData);
      // console.log('user', user);
      console.log('gamePlayerData1', gamePlayerData);
      SocketService.on('endTurn', handleEndTurnEvent);
      SocketService.on('playerAction', handlePlayerActionEvent);
      SocketService.on('connect', handleConnect);
      SocketService.on('disconnect', handleDisconnect);

      console.log('gameData', gameData);
      console.log('gamePlayerData', gamePlayerData);

      setPlayer(gamePlayerData);
      setGame({ ...gameData });
      loadLoot(gameData, gamePlayerData, refLocations.current);

      // scroll to current player
      const currentPlayer = document.getElementById(
        `player-${gameData.currentTurn.playerId}`
      );
      console.log('currentplayer', currentPlayer);
      if (currentPlayer) {
        currentPlayer.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }

      // if Game is completed show message
      if (gameData?.status === 'Completed') {
        setMessage(
          `${
            gameData.winningPlayer ? gameData.winningPlayer.name : 'No one won!'
          } wins!`
        );
      }
    }

    onLoad();
    return () => {
      console.log('UNBINDing playerAction');
      SocketService.off('playerAction');
      SocketService.off('endTurn');
      SocketService.off('connect', handleConnect);
      SocketService.off('disconnect', handleDisconnect);
      window.removeEventListener('keydown', handleEsc);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameId, user, locations]);
  // todo, create generic handler to
  async function handleMove() {
    const hex = path.end;

    const action = {
      gameId,
      playerId: player.id,
      turnId: game.currentTurn.id,
      actionTypeId: actionTypes.MOVE,
      data: {
        locationX: hex.q,
        locationY: hex.r,
        locationZ: hex.s,
      },
    };
    const response = await ExtractionAPI.createAction(action);
    console.log(response);
  }
  async function handleLoot(playerId) {
    const hex = path.end;

    const action = {
      gameId,
      playerId: player.id,
      turnId: game.currentTurn.id,
      actionTypeId: actionTypes.LOOT,
      data: {
        lootPlayerId: playerId,
        locationX: hex.q,
        locationY: hex.r,
        locationZ: hex.s,
      },
    };
    console.log('aaction', action);
    const response = await ExtractionAPI.createAction(action);
    console.log(response);
  }
  async function handleHealLimbs() {
    const action = {
      gameId,
      playerId: player.id,
      turnId: game.currentTurn.id,
      actionTypeId: actionTypes.HEAL_LIMBS,
    };
    const response = await ExtractionAPI.createAction(action);
    console.log(response);
  }
  async function handleCallExtract() {
    const action = {
      gameId,
      playerId: player.id,
      turnId: game.currentTurn.id,
      actionTypeId: actionTypes.CALL_EXTRACT,
    };
    const response = await ExtractionAPI.createAction(action);
    console.log(response);
  }
  async function handleExtract() {
    const action = {
      gameId,
      playerId: player.id,
      turnId: game.currentTurn.id,
      actionTypeId: actionTypes.EXTRACT,
    };
    const response = await ExtractionAPI.createAction(action);
    console.log(response);
  }
  async function handleUseLoot() {
    const loot = player.loots[activeCard];
    // Handle interactions with cards that require before action
    const action = {
      gameId,
      playerId: player.id,
      turnId: game.currentTurn.id,
      actionTypeId: actionTypes.PLAY_LOOT,
      data: {
        lootId: loot.id,
      },
    };
    setActiveCard(null);
    const response = await ExtractionAPI.createAction(action);
    console.log(response);
  }
  async function handleAttack() {
    const hex = path.end;
    console.log('attack', hex);

    const action = {
      gameId,
      playerId: player.id,
      turnId: game.currentTurn.id,
      actionTypeId: actionTypes.ATTACK,
      data: {
        targetPlayerId: hex.playerId,
      },
    };
    console.log('action', action);
    const response = await ExtractionAPI.createAction(action);
    console.log('response', response);
  }
  async function handleEndTurn({ game, player }) {
    setPath({ start: null, end: null });
    // Reset selected hand
    setActiveCard(null);
    if (player.loots.length > settings.MAX_HAND_SIZE) setShowDropLoot(true);
    else {
      const turnId = game.currentTurn.id;
      const response = await ExtractionAPI.endTurn(turnId);
      console.log('response', response);
    }
  }
  function onClick(event, source, loot) {
    // Variables
    const hex = source.props;
    console.log('hex clicked', hex);
    const playerHex = {
      q: player.locationX,
      r: player.locationY,
      s: player.locationZ,
    };
    const distance = HexUtils.distance(playerHex, hex);
    console.log(`Distance: ${distance}`);
    const actionCount = game.currentTurn.actions.reduce(
      (previousValue, action) => previousValue + action.value,
      0
    );
    const actionsRemaining = settings.ACTIONS_PER_TURN - actionCount;
    // Reset UI components
    setShowPlayerMat(false);
    setShowTargetPlayerMat(false);
    // If another player clicked, show player mat
    // console.log('x', hex.player, player.id, game.currentTurn.playerId);
    if (hex.player && player.id !== hex.playerId) {
      // console.log('player clicked', hex.player);
      // get player index
      const targetPlayerIndex = game.players.findIndex(
        (item) => hex.player.id === item.id
      );
      setTargetPlayer(targetPlayerIndex);
      setShowTargetPlayerMat(!showTargetPlayerMat);
    } else if (targetPlayer) {
      setTargetPlayer(null);
      setShowTargetPlayerMat(false);
    }
    // show location if clicked
    if (hex.location && locations.id !== hex.location.id && !hex.player) {
      console.log('location clicked', hex.location);
      // get location index
      const targetLocation = locations.find(
        (item) => hex.location.id === item.id
      );
      setZoomedCard(targetLocation);
      setShowZoomedCard(!showZoomedCard);
    } else if (zoomedCard) {
      setZoomedCard(null);
      setShowZoomedCard(false);
    }
    if (!validateAction.isMyTurn(game, player)) return;

    setPath({
      ...path,
      end: null,
      loot,
    });
    // Toggle path if same spot is clicked
    if (path.end && hex.q === path.end.q && hex.r === path.end.r) {
      return;
    }

    // Populate player loot data for state
    const playerLoots = {};
    if (hex.loot.length) {
      hex.loot.forEach((element) => {
        if (element.playerId) {
          if (!playerLoots[element.playerId]) {
            playerLoots[element.playerId] = {
              id: element.playerId,
              name: game.players.find((item) => item.id === element.playerId)
                .name,
            };
          }
        }
      });
    }
    console.log('game playerLoots', playerLoots);

    setPlayerLoot(
      Object.keys(playerLoots).map((key, index) => ({
        id: playerLoots[key].id,
        name: playerLoots[key].name,
      }))
    );

    // Determine if user can Move/Loot/Attack
    const canMove = validateAction.validateMove(
      game,
      player,
      source,
      distance,
      actionsRemaining
    );
    const canLoot = validateAction.validateLoot(
      game,
      player,
      source,
      distance,
      actionsRemaining
    );
    const canPlayerLoot = validateAction.validatePlayerLoot(
      game,
      player,
      source,
      distance,
      playerLoots
    );
    console.log('canPlayerLoot', canPlayerLoot);

    console.log('canLoot', canLoot);
    const canAttack = validateAction.validateAttack(
      game,
      player,
      source,
      distance,
      actionsRemaining
    );

    // const canAttack = validateAction.validateAttack();
    setAllowMove(canMove);
    setAllowLoot(canLoot);
    setAllowPlayerLoot(canPlayerLoot);
    setAllowAttack(canAttack);

    if (
      (canMove || canLoot || canAttack || canPlayerLoot) &&
      actionsRemaining
    ) {
      // Set path
      setPath({
        ...path,
        end: hex,
      });
    }
  }

  console.log('Render');

  if (!game?.currentTurn || !player || !locations || !hexagons)
    return <div className="loading">Loading</div>;
  // console.log('player', player);
  // loadLoot(game, player, locations);

  const isMyTurn = validateAction.isMyTurn(game, player);
  console.log('render actionType', actionType);
  return (
    <>
      {message && <FlashMessage message={message} game={game} />}
      <GameAttack
        setShowTargetPlayerMat={setShowTargetPlayerMat}
        attackData={attackData}
        setAttackData={setAttackData}
        game={game}
        setMessage={setMessage}
      />
      <GameDanger
        setShowTargetPlayerMat={setShowTargetPlayerMat}
        dangerData={dangerData}
        setDangerData={setDangerData}
        game={game}
        setMessage={setMessage}
      />
      <div className="Game">
        <div
          className="scrolling-view"
          style={{
            // background: '#444c43', //'rgb(201 183 135)',
            overflow: 'scroll',
            width: '100%',
            height: adjusted100vh,
          }}
          onClick={(e) => {
            if (e.target?.className === 'scrolling-view') {
              setShowPlayerMat(false);
              setShowTargetPlayerMat(false);
            }
          }}
        >
          <GameMenu />
          <GameObjective
            extractionLocation={player.extractionLocation}
            extractPoints={extractPoints}
          />

          {player.id === game.currentTurn.player.id &&
            game?.status !== 'Completed' &&
            actionsRemaining && (
              <GameEndTurn onClick={() => handleEndTurn({ game, player })} />
            )}

          <GameActions
            isMyTurn={isMyTurn}
            allowedActions={allowedActions}
            actionsRemaining={actionsRemaining}
            actionType={actionType}
            handleActionTypeSelect={setActionType}
          />

          <GameTurn
            player={
              player.id === game.currentTurn.player.id
                ? 'Your turn'
                : `${game.currentTurn.player.name}'s turn`
            }
          />
          <GameActionRemaining actions={game.currentTurn.actions} />

          {/* <GameTimeLeft timeLeft={timeLeft} /> */}
          <GameShowPlayerMat
            showPlayerMat={showPlayerMat}
            setShowPlayerMat={setShowPlayerMat}
          />
          {/* <GameShowActionLog
            showActionLog={showActionLog}
            setShowActionLog={setShowActionLog}
          /> */}
          <GamePlayerMat
            setShowZoomedCard={setShowZoomedCard}
            setZoomedCard={setZoomedCard}
            showPlayerMat={showPlayerMat}
            setShowPlayerMat={setShowPlayerMat}
            player={player}
          />
          <GamePlayerMat
            setShowZoomedCard={setShowZoomedCard}
            setZoomedCard={setZoomedCard}
            showPlayerMat={showTargetPlayerMat}
            setShowPlayerMat={setShowTargetPlayerMat}
            player={game.players[targetPlayer]}
            target={true}
          />
          <GameActionLog
            actionLog={actionLog}
            showActionLog={showActionLog}
            setShowActionLog={setShowActionLog}
          />
          {zoomedCard !== null && showZoomedCard && (
            <GameZoomCard
              card={zoomedCard}
              setShowZoomedCard={setShowZoomedCard}
            />
          )}
          {showDropLoot && (
            <DropLoot
              setShowDropLoot={setShowDropLoot}
              turnId={game.currentTurnId}
              loot={player.loots}
              lootIdsToDrop={lootIdsToDrop}
              setLootIdsToDrop={setLootIdsToDrop}
            />
          )}
          <GameHand
            loot={player.loots}
            showDropLoot={showDropLoot}
            setLootIdsToDrop={setLootIdsToDrop}
            lootIdsToDrop={lootIdsToDrop}
            activeCard={activeCard}
            setActiveCard={handleSetActiveCard}
          />
          {!!actionsRemaining &&
            activeCard !== null &&
            player.loots[activeCard].card.cardTypeId !== cardTypes.EXTRACT &&
            validateAction.isMyTurn(game, player) && (
              <GamePlayCard
                onClick={handleUseLoot}
                name={player.loots[activeCard].card.name}
              />
            )}
          {game?.status !== 'Completed' && !!actionsRemaining && (
            <GameConfirmAction
              playerLoot={playerLoot}
              actionType={actionType}
              pathEnd={path.end}
              allowMove={allowMove}
              allowLoot={allowLoot}
              allowAttack={allowAttack}
              handleMove={handleMove}
              handleLoot={handleLoot}
              handleAttack={handleAttack}
              handleHealLimbs={handleHealLimbs}
              handleCallExtract={handleCallExtract}
              handleExtract={handleExtract}
            />
          )}
          <GameGrid
            onClick={onClick}
            players={game.players}
            path={path}
            hexagons={hexagons}
          />
        </div>
      </div>
    </>
  );
}

export default Game;
