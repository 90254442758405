import React from 'react';

function GameObjective({ extractPoints, extractionLocation }) {
  return (
    <div
      style={{
        position: 'absolute',
        top: '8vw',
        left: '1.2vw',
        // width: '25vw',
        display: 'inline',
        color: 'rgb(201 183 135)',
        padding: '1vw',
        background: 'rgba(0, 0, 0, .2)',
        boxShadow: '0 0px 15px rgba(0, 0, 0, .5)',
        // border: '1px solid blue',
      }}
    >
      <div
        style={{
          fontSize: '1.5vw',
          fontFamily: "'GoodTimesRg-Regular'",
        }}
      >
        Objectives
      </div>

      <div
        style={{
          paddingTop: '.4vw',
          fontSize: '1.2vw',
          fontFamily: "'Black Ops One', cursive",
          fontWeight: '400',
          color: 'white',
        }}
      >
        - {extractPoints >= 6 ? `✅ ${extractPoints}` : extractPoints}/6 extract
        points
        {extractPoints >= 6 && !extractionLocation && (
          <div> - Call Extract</div>
        )}
        {extractPoints >= 6 && extractionLocation && (
          <div> - ✅ Call Extract</div>
        )}
        {extractPoints >= 6 && extractionLocation ? (
          <div>
            <div> - Extract at location:</div>

            <img
              className="card"
              src={require(`../assets/cards/${extractionLocation.img}`).default}
              alt={extractionLocation.name}
              style={{ position: 'relative', left: '2vw', width: '10vw' }}
            />
            <div
              style={{
                fontSize: '1.2vw',
                fontFamily: "'Black Ops One', cursive",
              }}
            >
              (Not visible to others)
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
export default GameObjective;
