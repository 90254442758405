import React, { useState, useEffect, useRef } from 'react';
import Dice from 'react-dice-roll';
// import ReactDice from 'react-dice-complete';
// import 'react-dice-complete/dist/react-dice-complete.css';
// audio
import useSound from 'use-sound';
import diceSound from '../assets/audio/618288__john-dark-souls__dice-roll.mp3';
import gunSound from '../assets/audio/563015__florianreichelt__a-loud-gunshot-in-the-woods.mp3';
import hitSound from '../assets/audio/173126__replix__death-sound-male.wav';

function GameAttack({
  attackData,
  setAttackData,
  setShowTargetPlayerMat,
  game,
  setMessage,
}) {
  const [step, setStep] = useState(0);
  const [rolledHit, setRolledHit] = useState(false);
  const [rolledLocation, setRolledLocation] = useState(false);
  const hitDiceRef = useRef(null);
  const locationDiceRef = useRef(null);
  const [playDiceSound] = useSound(diceSound);
  const [playGunSound] = useSound(gunSound);
  const [playHitSound] = useSound(hitSound);

  function resetState() {
    console.log('resetState');
    setRolledHit(false);
    setRolledLocation(false);
    setStep(0);
    setAttackData(null);
    setShowTargetPlayerMat(false);
  }
  function locationLabel(location) {
    switch (location) {
      case 'ARM_BREAK':
        return 'Arm Shot (Break)';

      case 'ARM':
        return 'Arm Shot';

      case 'LEG_BREAK':
        return 'Leg Shot (Break)';

      case 'LEG':
        return 'Leg Shot';

      case 'CHEST':
        return 'Chest Shot';

      case 'CHEST_ROLL':
        return 'Chest Shot + Roll again';

      case 'CUT':
        return 'Cut yourself (Attacker takes damage)';

      case 'HEAD':
        return 'Head Shot';

      case 'HEAD_ROLL':
        return 'Head Shot + Roll again';

      case 'UNEQUIP':
        return 'Weapon Malfunction (Unequip weapon)';
      default:
        return 'Unknown Location';
    }
  }
  useEffect(() => {
    function init() {
      if (!attackData) return;
      // Reset hit and location rolls
      // Roll
      hitDiceRef.current.rollDice();
      playGunSound();
      playDiceSound();
    }
    init();
  }, [attackData]);
  if (!attackData) return null;
  console.log('attackData', attackData);
  const { player, targetPlayer, result } = attackData;
  const {
    hit,
    hitRoll,
    accuracy,
    location,
    locationRoll,
    dmg,
    type,
    perkRoll,
    juggernaut,
  } = result[step];

  return (
    <div
      style={{
        width: '90vw',
        // height: '20vw',
        top: '50%',
        transform: 'translateY(-50%)',
        padding: '0 5vw',
        backgroundColor: 'black',
        position: 'fixed',
        color: 'white',
        textAlign: 'center',
        lineHeight: '6vh',
        opacity: '1',
        boxShadow: '0 0 10px 20px black',
        fontFamily: "'Black Ops One', cursive",
        fontSize: '2vw',
        zIndex: 11,
      }}
    >
      <div>
        {player.name} attacked {targetPlayer.name}!
      </div>
      {type === 'PERK_LAST_STAND' && (
        <div>
          <div>Last Stand Perk! Roll {accuracy}+ to stay alive!</div>

          <Dice
            ref={hitDiceRef}
            size={100}
            rollingTime={3000}
            cheatValue={perkRoll}
            disabled={true}
            onRoll={(value) => {
              console.log('on Roll!!');

              setTimeout(() => {
                setRolledHit(true);

                if (result[step + 1]) {
                  // go to next step
                  setStep(step + 1);
                  setRolledHit(false);
                } else {
                  // finished
                  console.log('finished', game);
                  if (game.status === 'Completed') {
                    setMessage(
                      `${
                        game.winningPlayer
                          ? game.winningPlayer.name
                          : 'No one won!'
                      } wins!`
                    );
                  }
                  resetState();
                }
              }, 4000);
            }}
          />
          {rolledHit && (
            <div>{hit ? 'SAVED BY LAST STAND!' : 'LAST STAND FAILED!'}</div>
          )}
        </div>
      )}
      {!hit && !type && (
        <div>
          <div>Roll {accuracy}+ to hit!</div>
          {/* <ReactDice
          numDice={2}
          rollDone={this.rollDoneCallback}
          ref={dice => this.reactDice = dice}
        	/> */}

          <Dice
            ref={hitDiceRef}
            size={100}
            rollingTime={3000}
            cheatValue={hitRoll}
            disabled={rolledHit}
            onRoll={(value) => {
              console.log('on Roll!1!');
              setRolledHit(true);

              playDiceSound();
              setTimeout(() => {
                if (result[step + 1]) {
                  // go to next step
                  setStep(step + 1);
                  setRolledHit(false);
                } else {
                  // finished
                  resetState();
                  setShowTargetPlayerMat(false);
                }
              }, 3000);
            }}
          />
          {rolledHit && <div>{hit ? 'HIT!' : 'MISSED!'}</div>}
        </div>
      )}
      {hit && (
        <React.Fragment>
          <div>
            <div>Roll {accuracy}+ to hit!</div>
            <Dice
              ref={hitDiceRef}
              size={100}
              rollingTime={3000}
              cheatValue={hitRoll}
              disabled={rolledHit}
              onRoll={(value) => {
                console.log('on Roll!');
                setRolledHit(true);
                playDiceSound();
                locationDiceRef.current.rollDice();
                if (hitRoll !== 1) {
                  setTimeout(() => {
                    playHitSound();
                  }, 3000);
                }
              }}
            />
            {rolledHit && <div>{hit ? 'HIT!' : 'MISSED!'}</div>}
          </div>
          {rolledHit && (
            <div>
              <div>Roll to see damage!</div>
              <Dice
                rollingTime={3000}
                size={100}
                ref={locationDiceRef}
                cheatValue={locationRoll}
                disabled={rolledLocation}
                onRoll={(value) => {
                  console.log('on Roll!');
                  setRolledLocation(true);
                  setTimeout(() => {
                    if (result[step + 1]) {
                      // go to next step
                      setStep(step + 1);
                      setRolledHit(false);
                      setRolledLocation(false);
                      playDiceSound();
                      hitDiceRef.current.rollDice();
                    } else {
                      // finished
                      console.log('finished', game);

                      if (game.status === 'Completed') {
                        setMessage(
                          `${
                            game.winningPlayer
                              ? game.winningPlayer.name
                              : 'No one won!'
                          } wins!`
                        );
                      }
                      resetState();
                    }
                  }, 4000);
                }}
              />
              {rolledLocation && (
                <>
                  {juggernaut && (
                    <div>
                      Juggernaut Roll ({juggernaut.hitRoll}){' '}
                      {juggernaut.hit ? 'Successful' : 'Failed'}
                    </div>
                  )}
                  <div>Damage: {dmg}</div>
                  <div>{locationLabel(location)}</div>
                </>
              )}
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
}
export default GameAttack;
