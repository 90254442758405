import React from 'react';
import settings from '../constants/settings';
function GameActionRemaining({ actions }) {
  const actionCount = actions.reduce(
    (previousValue, action) => previousValue + action.value,
    0
  );
  const actionsRemaining = settings.ACTIONS_PER_TURN - actionCount;

  return (
    <div
      className="actions"
      style={{
        background: 'rgba(29, 30, 34,.5)',
        zIndex: 5,
        fontSize: '2vw',
        fontFamily: "'Black Ops One', cursive",
        position: 'absolute',
        top: '1vw',
        color: 'white',
        borderRadius: '2vw',
        padding: '1vw 2vw',
        right: '19vw',
      }}
    >
      {actionsRemaining} Actions remain
    </div>
  );
}
export default GameActionRemaining;
