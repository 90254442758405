import React from 'react';
import parachute from '../assets/parachute.svg';
import player from '../assets/player.svg';
import dead from '../assets/dead.svg';
import grass from '../assets/grass_small.png';

import { HexGrid, Layout, Path, Hexagon, Pattern } from '../lib/react-hexgrid';
function GameGrid({ hexagons, onClick, path, players }) {
  const playerColors = {};
  players.map((item) => {
    playerColors[item.id] = item.color;
  });
  console.log('playerColors', playerColors);
  return (
    <div style={{ display: 'inline-block', margin: '15vw' }}>
      <HexGrid width={1200} height={1000} viewBox={'-55 -70 140 140'}>
        <Layout
          size={{ x: 8, y: 8 }}
          flat={false}
          spacing={1.02}
          origin={{ x: -60, y: -60 }}
        >
          {hexagons.map((hex, i) => {
            const className = hex?.props?.className || 'grass';
            const fill = hex?.props?.fill;
            const player = hex?.props?.player || null;
            const extractingLabel = player?.extractionLocationId ? '(!)' : '';
            const playerId = player?.id || null;
            const playerColor = player?.color || null;
            const location = hex?.props?.location || null;
            const loot = hex?.props?.data?.loot || [];
            const nonPlayerLoots = loot.filter((item) => !item.isDropped);
            const label = player ? player.name + extractingLabel : '';
            const playerLoots = {};
            if (loot.length) {
              loot.forEach((element) => {
                if (element.playerId) {
                  if (!playerLoots[element.playerId]) {
                    playerLoots[element.playerId] = {
                      color: playerColors[element.playerId],
                      count: 1,
                    };
                  } else {
                    playerLoots[element.playerId].count += 1;
                  }
                }
              });
            }
            // console.log('playerLoots', playerLoots);
            return (
              <Hexagon
                id={playerId ? `player-${playerId}` : null}
                loot={loot}
                cellStyle={playerColor ? { stroke: playerColor } : null}
                fill={fill}
                key={`hex${i}`}
                q={hex.q}
                r={hex.r}
                s={hex.s}
                playerId={playerId}
                player={player}
                location={location}
                className={className}
                onClick={(e, h) => onClick(e, h, loot)}
              >
                <>
                  <text textAnchor="middle" alignmentBaseline="middle">
                    {label && (
                      <tspan dy="0em" x="0">
                        {extractingLabel ? (
                          <>
                            <tspan>(</tspan>
                            <tspan fill="red">!</tspan>
                            <tspan>)</tspan>
                          </>
                        ) : null}
                        {label}
                      </tspan>
                    )}
                    <tspan dy={label ? '1.2em' : '0'} x="0" fill="white">
                      {nonPlayerLoots.length
                        ? `(${nonPlayerLoots.length})`
                        : null}
                    </tspan>
                    {Object.keys(playerLoots).map((key, index) => (
                      <tspan
                        key={i}
                        dy={
                          label && !nonPlayerLoots.length && index === 0
                            ? '1.2em'
                            : '0'
                        }
                        x={!nonPlayerLoots.length && index === 0 ? '0' : null}
                        fill={playerLoots[key].color}
                      >
                        ({playerLoots[key].count})
                      </tspan>
                    ))}
                  </text>
                  {/* {loot.length && (
                    <text y={3} textAnchor="middle" alignment-baseline="middle">
                      ({loot.length})
                    </text>
                  )} */}
                </>
              </Hexagon>
            );
          })}
          <Path start={path.start} end={path.end} />
        </Layout>
        <Pattern
          id="grass"
          link={null}
          size={{ x: 8, y: 8 }}
          bgColor={'darkolivegreen'}
        />

        <Pattern
          id="location0"
          link={parachute}
          bgColor={'#1682C5'}
          size={{ x: 8, y: 8 }}
        />
        <Pattern
          id="location1"
          link={parachute}
          bgColor={'#1AB24F'}
          size={{ x: 8, y: 8 }}
        />
        <Pattern
          id="location2"
          link={parachute}
          bgColor={'#47C4D5'}
          size={{ x: 8, y: 8 }}
        />
        <Pattern
          id="location3"
          link={parachute}
          bgColor={'#B2D236'}
          size={{ x: 8, y: 8 }}
        />
        <Pattern
          id="location4"
          link={parachute}
          bgColor={'#DE7E26'}
          size={{ x: 8, y: 8 }}
        />
        <Pattern
          id="location5"
          link={parachute}
          bgColor={'#B14D9D'}
          size={{ x: 8, y: 8 }}
        />
        <Pattern
          id="location6"
          link={parachute}
          bgColor={'#E22627'}
          size={{ x: 8, y: 8 }}
        />
        <Pattern
          id="location7"
          link={parachute}
          bgColor={'#FAED23'}
          size={{ x: 8, y: 8 }}
        />

        <Pattern id="moverange" link={grass} size={{ x: 8, y: 8 }} />
        <Pattern
          id="dead"
          bgColor="rgb(201 183 135)"
          link={dead}
          size={{ x: 6, y: 6 }}
          offsetX={1}
          offsetY={2}
        />
        <Pattern
          id="player"
          bgColor="none"
          link={player}
          size={{ x: 6, y: 6 }}
          offsetX={0}
          offsetY={2}
        />
      </HexGrid>
    </div>
  );
}
export default GameGrid;
