import React from 'react';
import playerIcon from '../assets/player.svg';

function GameShowPlayerMat({ setShowPlayerMat, showPlayerMat }) {
  return (
    <>
      <img
        alt="player"
        src={playerIcon}
        style={{
          position: 'absolute',
          left: '2.5vw',
          bottom: '3.5vw',
          width: '5.5vw',
          zIndex: 7,
          marginTop: '-47.3vw',
          background: 'rgba(255,255,255,.5)',
          padding: '1vw',
          boxShadow: '0 0px 15px rgba(0, 0, 0, .5)',

          borderRadius: '6.5vw',
        }}
        onClick={() => {
          console.log('showPlayerMat', showPlayerMat);
          setShowPlayerMat(!showPlayerMat);
        }}
      />
    </>
  );
}
export default GameShowPlayerMat;
