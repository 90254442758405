import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Point from './models/Point';
// import loading from '../../assets/loading.gif';

class Pattern extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    // link: PropTypes.isRequired,
    size: PropTypes.object,
  };
  static defaultProps = {
    size: new Point(10, 10),
    offsetX: 0,
    offsetY: 0,
  };

  render() {
    const { id, link, size, bgColor, offsetX, offsetY } = this.props;
    // const xLinkHref = 'https://www.wurmpedia.com/images/c/c5/Grass.png'; //local ? localImage[local] : link;
    const xLinkHref = link;
    // console.log('xLinkHref', typeof xLinkHref, xLinkHref);
    // console.log('this.props', this.props);
    return (
      <defs>
        <pattern
          id={id}
          patternUnits="objectBoundingBox"
          x={0}
          y={0}
          width={size.x}
          height={size.y}
        >
          <rect
            x="0"
            y="0"
            width={size.x * 2}
            height={size.y * 2}
            fill={bgColor}
          />

          <image
            xlinkHref={xLinkHref}
            x={offsetX}
            y={offsetY}
            width={size.x * 2}
            height={size.y * 2}
          />
        </pattern>
      </defs>
    );
  }
}

export default Pattern;
