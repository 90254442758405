import React, { useEffect, useMemo, useState } from 'react';
import './App.css';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Pages
import Login from './pages/Login';
import Game from './pages/Game';
import Games from './pages/Games';
import GameLobby from './pages/GameLobby';

// context
import {
  GameContext,
  GamesContext,
  UserContext,
} from './context/ExtractionContext';

// services
import SocketService from './services/SocketService';
import ExtractionAPI from './services/ExtractionAPI';

function App() {
  console.log('App Render');
  const [disconnected, setDisconnected] = useState(false);
  const [game, setGame] = useState(null);
  const [user, setUser] = useState(null);
  // user
  const userMemo = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user, setUser]
  );

  // game
  const gameMemo = useMemo(
    () => ({
      game,
      setGame,
    }),
    [game, setGame]
  );

  // games
  const [games, setGames] = useState([]);
  const gamesMemo = useMemo(
    () => ({
      games,
      setGames,
    }),
    [games, setGames]
  );
  useEffect(() => {
    // socket functions
    const onConnect = () => {
      console.log('logging in to SocketService server');
      const userId = localStorage.getItem('userId');
      if (userId) {
        console.log(`Login to socket: ${userId}`);
        console.log('game', game);
        console.log('gameMemo', gameMemo);
        SocketService.emit('login', { id: parseInt(userId, 10) });
        // todo
        // find a better way to reconnect
        // maybe bind disconnect/connect handlers in game component
        // Check if game was set, if so reload to ensure
        // if (game) {
        //   console.log('Reconnecting..');
        //   window.location.reload();
        // }
      }
    };
    const onGameCreate = ({ count, rows }) => {
      console.log('Game created event', count, rows);
      // listGames();
      setGames(rows);
    };

    async function onLoad() {
      console.log('App Onload');

      // Socket Connect
      try {
        // If token exists get user
        const token = localStorage.getItem('token');
        if (token) {
          // Get user
          const user = await ExtractionAPI.getMe();
          console.log('Logged in User', user);
          setUser(user);
        }
        SocketService.on('connect', onConnect);
        SocketService.on('gameCreated', onGameCreate);
        SocketService.on('connect_error', (err) => console.error(err));
        SocketService.on('error', (err) => console.error(err));
        SocketService.on('disconnect', (reason) => {
          console.log('Socket Disconnect', reason);
          // SocketService.removeAllListeners();
          // console.log('SocketService.removeAllListeners');
          if (reason === 'io server disconnect') {
            console.log('io server disconnect');
            // the disconnection was initiated by the server, you need to reconnect manually
            console.log('Reconnecting...');

            SocketService.connect();
          }
          // else the SocketService will automatically try to reconnect
        });
        console.log('Connect to socket...');

        SocketService.connect();
      } catch (e) {
        console.log('errr', e);
      }
    }
    onLoad();
    return () => {
      console.log('unmount app');
      // unmount
      SocketService.off('connect', onConnect);
    };
  }, []);
  // game
  return (
    <UserContext.Provider value={userMemo}>
      <GamesContext.Provider value={gamesMemo}>
        <GameContext.Provider value={gameMemo}>
          <Router>
            <Switch>
              <Route exact path="/games">
                <Games />
              </Route>
              <Route path="/lobby/:gameId">
                <GameLobby />
              </Route>
              <Route path="/game/:gameId">
                <Game />
              </Route>
              <Route path="/">
                <Login />
              </Route>
            </Switch>
          </Router>
        </GameContext.Provider>
      </GamesContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
