import React from 'react';

function GameTurn({ player }) {
  return (
    <div
      className="turn"
      style={{
        background: 'rgba(29, 30, 34,.5)',
        zIndex: 5,
        fontFamily: "'Black Ops One', cursive",
        fontSize: '2vw',
        position: 'absolute',
        top: '1vw',
        color: 'white',
        borderRadius: '2vw',
        padding: '1vw 2vw',
        right: '42.5vw',
      }}
    >
      {player}
    </div>
  );
}
export default GameTurn;
