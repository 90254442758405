const actionTypes = {
  MOVE: 1,
  LOOT: 2,
  PLAY_LOOT: 3,
  ATTACK: 4,
  HEAL_LIMBS: 5,
  EXTRACT: 6,
  CALL_EXTRACT: 8,
};
export default actionTypes;
