function isMyTurn(game, player) {
  if (player.id !== game.currentTurn.playerId) return false;
  return true;
}
function validateMove(game, player, source, distance, actionsRemaining) {
  console.log('xsource', source);
  const { hex } = source.state;

  // Cant Move if not your turn
  if (player.id !== game.currentTurn.playerId) return false;

  // Prevent Player from moving to another players hex
  if (
    game.players.find(
      (player) => player.locationX === hex.q && player.locationY === hex.r
    )
  ) {
    console.log('Clicked on player ignoring');
    return false;
  }

  // Check Range
  const maxRange = player.isLegBroken
    ? Math.floor(actionsRemaining / 2)
    : actionsRemaining;

  if (distance > maxRange || distance < 1) return false;
  return true;
}
function validateLoot(game, player, source, distance, actionsRemaining) {
  // variables
  const { loot } = source.props;

  // Cant loot if not your turn
  if (player.id !== game.currentTurn.playerId) return false;

  // Check if next to spot
  if (distance > 1) return false;

  // Check if loot
  if (!loot.length) return false;
  // Check if any loot does not have a player id
  if (!loot.find((item) => item.playerId === null)) return false;
  return true;
}
function validatePlayerLoot(game, player, source, distance, playerLoots) {
  console.log('validatePlayer');
  const { loot } = source.props;

  // Cant loot if not your turn
  if (player.id !== game.currentTurn.playerId) return false;
  console.log('validatePlayer1');

  // Check if next to spot
  if (distance > 1) return false;
  console.log('validatePlayer2');

  // Check if loot
  console.log('playerLoots', playerLoots.length, playerLoots);

  // CHeck if any loot does not have a player id
  if (!loot.find((item) => item.playerId !== null)) return false;

  return true;
}
function validateAttack(game, player, source, distance, actionsRemaining) {
  const playerId = source.props.playerId;

  // Check if player
  if (!playerId) return false;
  // Cant Move if not your turn
  console.log('source', source);
  console.log(game, source, distance, actionsRemaining);
  if (player.id !== game.currentTurn.playerId) {
    console.log('Not your turn');
    return false;
  }

  // Check if weapon is equipped
  const weaponCard = player.weaponCard;
  if (!weaponCard) return false;
  console.log('weaponCard.data', distance, weaponCard.data);
  // Check if in range
  const rangeBonus = player?.perkCard?.data?.type === 'PERK_MARKSMAN' ? 1 : 0;
  console.log('rangeBonus', rangeBonus);
  const minRange = weaponCard.data.minRange;
  const maxRange = weaponCard.data.maxRange + rangeBonus;

  if (distance < minRange || distance > maxRange) return false;

  // Check if player has at least two actions remaining
  if (actionsRemaining < 2) return false;

  return true;
}

const validateAction = {
  validateMove,
  validateLoot,
  validatePlayerLoot,
  validateAttack,
  isMyTurn,
};
export default validateAction;
